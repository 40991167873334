@media only screen and (max-device-width: 700px) {
    .page  { width: 100%; } 
}

body{
    background-color: #F0F4F5 !important;
}
a, p, span, div, ul, li{
    font-family: Roboto, sans-serif;
}

h1, h2, h3, h4, h5, h6{
    font-family: Roboto,sans-serif;
    color: #37474f;
    margin:0;
}

// EFEITO DE BOTÃO HOVER COM SHAKE
.buttonDisable {
    &:hover{
        animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

  // TERMINA O EFEITO DO BOTÃO


//   REMOVER BORDA AZUL
textarea:focus, input:focus, select:focus {
    outline: 0;
} 